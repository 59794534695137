import React, { useMemo, useState } from 'react';
import styles from './index.module.less';
import Link from 'next/link';
import { pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { useUserProvider } from '@/context/UserProvider';
import { Carousel } from 'antd';
// import EnterWebsiteModal from '@/components/Common/EnterWebsiteModal';
// import DataComparisonModal from '@/components/Dashboard/DashboardList/DataComparisonModal';
import dynamic from 'next/dynamic';
const EnterWebsiteModal = dynamic(() => import('@/components/Common/EnterWebsiteModal'), {
  ssr: false,
});

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '36px',
  lineHeight: '36px',
  color: '#BAC3D0',
  fontSize: '14px',
};

const LoginStateInfo = ({ pageType }: { pageType?: PageType }) => {
  const [pageMetaData, _] = useState(pageMetadataMap[pageType ?? PageType.Normal])
  const { isLogin } = useUserProvider()
  const [showCarousel, setShowCarousel] = useState(true);
  const [searchValue, setSearchValue] = useState<string>(null);
  const [isOpenCreateWebLink, setIsOpenCreateWebLink] = useState(false);

  const list = useMemo(() => {
    return [
      {
        imgPath: '/images/home/home-AI-Analysis-icon.webp',
        title: 'AI Analysis',
      },
      {
        imgPath: '/images/home/home-Targeted-Monitoring-icon.webp',
        title: 'Targeted Monitoring',
      },
      {
        imgPath: '/images/home/home-Instant-Alerts-icon.webp',
        title: ('Instant Alerts'),
      },
      {
        imgPath: '/images/home/home-Targeted-Monitoring-icon.webp',
        title: ('Visual Result Comparison'),
      },
    ]
  }, [])

  const onSubmit = () => {
    setTimeout(() => {
      setIsOpenCreateWebLink(true)
    }, 1);
  }

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className={styles.topContent}>
          <div className={styles.titleContent}>
            <h1>
              {pageMetaData.title1Key}
            </h1>
            <p>{pageMetaData.titleDescKey}</p>

            {isLogin && <Link href={`/dashboard`}>
              <span className={styles.dashboard}>{`Dashboard`}</span>
            </Link>}

            {!isLogin && <div className={styles.searchContainer}>

              <div className={styles.carouselContainer}>
                {!showCarousel && <input
                  autoFocus
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e?.target.value)}
                  className={styles.inputContainer}
                  placeholder="Search..."
                  onBlur={(e) => {
                    e?.stopPropagation()
                    setShowCarousel(true)
                  }}
                />}

                {showCarousel && <Carousel
                  dotPosition="left"
                  dots={false}
                  autoplay
                >
                  {['product page',
                    'social media',
                    'website',
                    'news',
                    'event page'].map((item, index) => {
                      return <div
                        className={styles.carouselItem}
                        key={index}
                        onClick={(e) => {
                          e?.stopPropagation()
                          setShowCarousel(false)
                        }}>
                        <p style={contentStyle}>
                          Enter a <span style={{ color: '#1E5EFF' }}>{item}</span> URL:
                        </p>
                      </div>
                    })}
                </Carousel>}
              </div>

              <span className={styles.go} onClick={onSubmit}>
                {`Go >`}
              </span>
            </div>}
          </div>
          <div className={styles.videoContent}>
            <video src="https://www.youtube.com/watch?v=mt8UkFYfSBk" controls style={{ aspectRatio: '16/9', width: '100%' }} />
          </div>
        </div>
        <div className={styles.bottomContent}>
          {list?.map((item, index) => {
            return <div className={styles.listItem} key={item?.title}>
              <Image src={item?.imgPath} alt={''} width={48} height={48} />
              <span>{item?.title}</span>
            </div>
          })}
        </div>
      </div>
    );
  }, [pageMetaData, isLogin, showCarousel, searchValue]);

  return <>
    {getContent}
    {isOpenCreateWebLink && <EnterWebsiteModal defaultValue={searchValue?.trim()} onClose={(isOk?: boolean) => setIsOpenCreateWebLink(false)} />}
    {/* {isOpenCreateWebLink && <DataComparisonModal onClose={(isOk?: boolean) => setIsOpenCreateWebLink(false)} />} */}

  </>;
};

export default LoginStateInfo;
